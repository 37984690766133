<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <div class="text-red font-weight-bold pt-1">
                    <q-icon size="1rem" color="red" name="delete" />&nbsp;
                    {{translate('delete_customer')}}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item>
                    {{ translate('deleting_customer') }}
                </b-list-group-item>
                <b-list-group-item v-if="msg !== null">
                    <div class=" p-3 bg-danger text-white">
                        {{ msg }}<br />
                        {{ translate('msg_customer_is_user_deletion_recommendation') }}
                    </div>
                </b-list-group-item>
                <b-list-group-item>
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <q-btn no-caps @click="deleteCustomer" type="submit" color="danger">
                                <q-icon class="mr-1" size="1rem" name="check" /> {{ translate('yes') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                <q-icon class="mr-1" size="1rem" name="cancel" /> {{ translate('no') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
export default {
    name: 'DeleteCustomer',
    props: ['customer_id'],
    data() {
        return {
            msg: null,
        }
    },
    methods: {
        deleteCustomer: function () {
            var customerUrl = baseUrl + 'customers/' + this.customer_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.delete(customerUrl, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'delete_customer_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.msg === 'is_user') {
                        this.msg = translated_message
                    }
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                        eventBus.$emit('delete_customer', this.customer_id)
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>

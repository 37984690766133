<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3> {{translate('customers')}}</h3>
        </div>
        <search-wrapper-card :active="customer_active" :activeStatus="activeStatus" :inactive="customer_inactive" :inactiveStatus="inactiveStatus" :typeDisable="false" form_type="customers">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternamecustomer" class="search-input" type="text" :placeholder="translate('find_client')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddCustomerPanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_customer') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddCustomerPanel" />
            </template>
        </search-wrapper-card>
    </b-card>
    <div class="list-group wrapper_worker-customers">
        <q-item v-for="(customer,i) in customers" :key="'customer-' + i + '-' + customer.id" :id="customer.id" class="list-item full-width">
            <q-item-section avatar class="p-0">
                <q-avatar class="ml-2 shadow-2 cursor-pointer" @click.prevent="showEditCustomerPanel(customer.id, customer.is_active)">
                    <img v-if="customer.image_link" class="black-and-white" :src="customer.image_link">
                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                </q-avatar>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <strong class="text-muted">{{ customer.name }} &lt;{{ translate(customer.email) }}&gt;</strong>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm">
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="groups" color="blue-6" @click="showCustomerGroupsPanel(customer.id)">
                        <q-tooltip :offset="[10, 10]">{{ translate('view_customer_groups') }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="edit" color="primary" @click="showEditCustomerPanel(customer.id, customer.is_active)">
                        <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="delete" color="danger" @click="showDeletePanel(customer.id)">
                        <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <toggle-active :is_active="customer.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="customer.id" :entity_type="entity_type" />
                </q-item-section>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </div>
    <infinite-loading :identifier="'customers-' + infCustomers" slot="append" @infinite="getCustomers">
    </infinite-loading>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import EditCustomer from './forms/EditCustomer'
import AddCustomer from './forms/AddCustomer'
import DeleteCustomer from './forms/DeleteCustomer'
import ToggleActive from '../../template-parts/ToggleActive'
import CustomerGroups from './show/CustomerGroups'

export default {
    name: 'Customers',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            infCustomers: +new Date(),
            customers: [],
            searchQuery: "",
            customer_active: 'active',
            customer_inactive: '',
            order_by: '',
            isTyping: false,
            show: true,
            page: 1,
            state: {},
        }
    },
    computed: {
        entity_type: function () {
            return 'customer'
        },
        patch_toggle_route: function () {
            return baseUrl + 'customers'
        },
    },
    mounted: function () {
        eventBus.$on('toggle_active_customer', (item) => {
            this.toggleActiveCustomer(item)
        })
        eventBus.$on('delete_customer', (id) => {
            this.deleteCustomer(id)
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        updateCustomerName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.customers.findIndex((obj => obj.id === item.customer.id))
            // and update the element from the customers array.
            this.customers[objIndex]['name'] = item.customer.name ? item.customer.name : ''
        },
        toggleActiveCustomer: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.customers.findIndex((obj => obj.id === item.id))
                if (this.customers[objIndex]) {
                    // And update is_active for the given item in list.
                    this.customers[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.customer_active === 'active' && this.customer_inactive === '') {
                        this.deleteCustomer(item.id)
                    }
                    if (item.is_active === 1 && this.customer_active === '' && this.customer_inactive === 'inactive') {
                        this.deleteCustomer(item.id)
                    }
                }
            }
        },
        deleteCustomer: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.customers.findIndex((obj => obj.id === id))
            // and remove the element from the customers array.
            this.customers.splice(objIndex, 1)
        },
        resetMainList: function () {
            this.page = 1
            this.customers = []
            this.infCustomers++
        },
        activeStatus: _.debounce(function () {
            if (this.customer_active === 'active') {
                this.customer_active = ''
            } else {
                this.customer_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.customer_inactive === 'inactive') {
                this.customer_inactive = ''
            } else {
                this.customer_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        patchActivate: function (id) {
            var status = this.customers.map(parent2 => {
                let array = parent2
                return array.is_active
            })
            var active = status ? 1 : 0
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                'is_active': active,
            }
            axios.patch(baseUrl + 'customers/' + id + '/is_active/' + active, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('Your status is updated', {
                            duration: this.$toasted_duration
                        })
                        this.resetMainList()
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        patchDeactivate: function (id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var status = this.customers.map(parent2 => {
                let array = parent2
                return array.is_active
            })
            var active = status ? 0 : 1

            var data = {
                'is_active': active,
            }
            axios.patch(baseUrl + 'customers/' + id + '/is_active/' + active, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('Your status is updated', {
                            duration: this.$toasted_duration
                        })
                        this.resetMainList()
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getCustomers: function ($state) {
            var customerUrl = baseUrl +
                'customers?page=' + this.page +
                '&items_per_page=' + this.$items_per_page
            if (this.searchQuery) {
                customerUrl += '&search=' + this.searchQuery
            }
            if (this.customer_active === 'active' && this.customer_inactive === '') {
                customerUrl += '&customer_status=' + this.customer_active
            }
            if (this.customer_active === '' && this.customer_inactive === 'inactive') {
                customerUrl += '&customer_status=' + this.customer_inactive
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let customers = this.customers
            axios.get(customerUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        customers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.customers = customers
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddCustomerPanel() {
            const panelInstance = this.$showPanel({
                component: AddCustomer,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showDeletePanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteCustomer,
                props: {
                    customer_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showEditCustomerPanel(id, is_active) {
            const panelInstance = this.$showPanel({
                component: EditCustomer,
                props: {
                    customer_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateCustomerName(result)
                    }
                })
        },
        showCustomerGroupsPanel(id) {
            const panelInstance = this.$showPanel({
                component: CustomerGroups,
                props: {
                    customer_id: id,
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img.black-and-white {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    &:hover {
        border-color: rgba(0, 0, 0, .26);
        text-decoration: none;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}
</style>
